import React, { useState, useEffect } from "react";
import "./NavBar.css";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from "firebase/auth";
import { app } from "./firebaseConfig";


function NavBar() {
  const [showMenu, setShowMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const auth = getAuth();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); // New state to check if user is logged in

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        console.log("User is signed in with uid: ", uid);
        setIsUserLoggedIn(true); // If user is logged in, update the state
      } else {
        console.log("User is signed out");
        setIsUserLoggedIn(false); // If user is logged out, update the state
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
    try {
      const result = await signInWithPopup(auth, provider);
      // The signed-in user info.
      const user = result.user;
      console.log(user); // You can remove this line after confirming it works
    } catch (error) {
      console.error(error);
    }
  };
  const signOutUser = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (showMenu && !event.target.closest(".menu-button")) {
        document.querySelector(".menu-button").classList.add("shrink");
        document.querySelector(".menu-button").classList.remove("grow");
        setIsOpen(!isOpen);
        setShowMenu(!showMenu);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [showMenu]);
  

  const handleMenuClick = () => {
    if(showMenu){
        document.querySelector('.menu-button').classList.add('shrink');
        document.querySelector('.menu-button').classList.remove('grow');
    }else{
        document.querySelector('.menu-button').classList.remove('shrink');
        document.querySelector('.menu-button').classList.add('grow');
    }
    setIsOpen(!isOpen);
    setShowMenu(!showMenu);
    
    
  };


  const handleMenuClose = () => {
    if(showMenu){
        document.querySelector('.menu-button').classList.add('shrink');
        document.querySelector('.menu-button').classList.remove('grow');
    }
  };

  const handleMenuItemClick = (page) => {
    // handle click for menu item
    console.log(`Navigating to ${page}`);
  };

  return (
    <div>
        <div className="nav-bar">
        <div className="menu-button" >
            <div classname ="icons" onClick={handleMenuClick}>
            <i className="fa fa-bars" ></i></div>
        </div>
        {showMenu && (
            <div className={`menu-container${isOpen ? ' open' : ''}`}>
                {isUserLoggedIn && (
                <div className="menu-item"  onClick={signOutUser}>Sign Out</div>)}
                {!isUserLoggedIn && (
                <div className="menu-item" onClick={signInWithGoogle}>Sign In</div>)}
            </div>
        )}
        </div>
    </div>
  );
}

export default NavBar;
