import React from "react";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="lloading">
      <div className="lloading-spinner"></div>
      <h2 className="lloading-h2">Generating...</h2>
    </div>
  );
};

export default Loading;
