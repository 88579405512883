import React, { useState, useEffect, useRef  } from 'react';
import './Interface.css';
import { GET, POST } from './../api';
import Loading from './../Loading';
import logo from "./../images/pluggingai.png";
import Select from 'react-select'; // Import react-select
import VantaBirds from './Vanta';
import toWav from 'audiobuffer-to-wav'; // Add this import at the top of your file
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginPopup from './loginPopup';
import { useNavigate } from 'react-router-dom'; 

import RecordRTC from 'recordrtc';


function SoVitsInference() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [speakers, setSpeakers] = useState([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState('');
  const [transpose, setTranspose] = useState(0);
  const [clusteringRatio, setClusteringRatio] = useState(0.0);
  const [noiseScale, setNoiseScale] = useState(0.4);
  const [autoPitch, setAutoPitch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [audioUrl, setAudioUrl] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(''); // Add a new state for the uploaded file name
  const fileInput = useRef();
  const [showAdvanced, setShowAdvanced] = useState(false); // Add a new state for showing advanced options
  const [isRecording, setIsRecording] = useState(false);
  const [recordedData, setRecordedData] = useState(null);
  const speakerOptions = speakers.map(speaker => ({ value: speaker.name, label: speaker.name }));
  const [showAudio, setShowAudio] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [stream, setStream] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false); // New state to control the display of the login popup
  const [isFormIncomplete, setIsFormIncomplete] = useState(true);



  const auth = getAuth();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); // New state to check if user is logged in
  const history = useNavigate(); // Initialize useHistory

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        console.log("User is signed in with uid: ", uid);
        setIsUserLoggedIn(true); // If user is logged in, update the state
      } else {
        console.log("User is signed out");
        setIsUserLoggedIn(false); // If user is logged out, update the state
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  async function toggleRecording() {
    if (!isRecording) {
      // Start recording
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          const recorder = RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/webm',
            disableLogs: true,
            recorderType: RecordRTC.StereoAudioRecorder,
            numberOfAudioChannels: 1,
          });
  
          recorder.startRecording();
          setMediaRecorder(recorder);
          setStream(stream);
          setIsRecording(true);
        } catch (err) {
          console.error('Could not start recording:', err);
        }
      } else {
        alert('Recording is not supported in your browser.');
      }
    } else {
      // Stop recording
      if (mediaRecorder) {
        mediaRecorder.stopRecording(() => {
          const blob = mediaRecorder.getBlob();
          setSelectedFile(new File([blob], "custom_recording.wav", { type: "audio/wav" }));
          setUploadedFileName("custom_recording.wav");
          
          // Stop the stream
          if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);  // clear the stream
          }
        
          mediaRecorder.destroy();
          setMediaRecorder(null);
        });
        setIsRecording(false);
        setIsFormIncomplete(false);

      }
    }
  }
  
  

  function toggleAdvanced() {
    setShowAdvanced(!showAdvanced);
  }

  // Fetch available speakers from server on component mount
  useEffect(() => {
    GET('speakers')
      .then(data => setSpeakers(data))
      .catch(error => console.error(error));
  }, []);

  // Handle file selection
  function handleFileSelection(event) {
    setSelectedFile(event.target.files[0]);
    setUploadedFileName(event.target.files[0]?.name || '');
    
    if (event.target.files[0] && selectedSpeaker) {
      setIsFormIncomplete(false);
    } else {
      setIsFormIncomplete(true);
    }
  }

  function handleSpeakerSelection(event) {
    setSelectedSpeaker(event.target.value);
    
    if (event.target.value && selectedFile) {
      setIsFormIncomplete(false);
    } else {
      setIsFormIncomplete(true);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (!isUserLoggedIn) {
      // If user is not logged in, show pop up
      setShowLoginPopup(true);
    } else if (isFormIncomplete) {
      // If form is incomplete, show error message
    } else {
      setIsLoading(true);
      setErrorMessage();
    
      const formData = new FormData();
      formData.append('audio_file', recordedData || selectedFile);
      formData.append('speaker', selectedSpeaker);
      formData.append('transpose', transpose);
      formData.append('clustering_ratio', clusteringRatio);
      formData.append('noise_scale', noiseScale);
      formData.append('auto_pitch', autoPitch);
    
      try {
        const response = await POST('infer', formData);
        const data = await response;
        setAudioUrl(data.filename); // update audio URL state
        setShowAudio(true); // Show the audio overlay
        setIsLoading(false);
        
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(error.message);
      }
    }
  }
  
  function handleDone() {
    setShowAudio(false); // Hide the audio overlay
  }

  function handleDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  }
  
  function handleDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files.length > 0) {
      setSelectedFile(event.dataTransfer.files[0]);
      setUploadedFileName(event.dataTransfer.files[0]?.name || '');
    }
  }

  async function downloadFile(audioUrl) {
    const response = await fetch(audioUrl);
    const data = await response.blob();
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'audio.wav';  // or another filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  

  return (
    <div className="interface-container"><VantaBirds /><center>
    <div className="form-container">
      <center><img className='home-image-logo' alt='SpryList logo' src={logo} /></center>
      <form onSubmit={handleSubmit}>
      
        <div className="form-group">
            <div>Select an audio file:</div>
            <input
              type="file"
              onChange={handleFileSelection}
              ref={fileInput}
              style={{ display: 'none' }}
            />
            <div
              className={`file-input-container${isRecording ? ' file-input-container-recording' : ''}`}
              onClick={() => fileInput.current.click()}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {isRecording ? (
                <div className="recording-text">🔴</div>
              ) : (
                <div
                  className={`file-name${uploadedFileName === "custom_recording.wav" ? ' red-text' : ''}`}
                >
                  {uploadedFileName || 'Browse files or drag and drop'}
                </div>
              )}
            </div>
        </div>
        <div className="or-divider">
          or
        </div>
        <div className="form-group">
          <button className="record-button" type="button" onClick={toggleRecording}>
            {isRecording ? 'Stop Recording' : 'Record Audio'}
          </button>
        </div>
        <div className="form-group">
          <label>
            <select className="options" value={selectedSpeaker} onChange={handleSpeakerSelection}>
              <option value="">    Select a model</option>
              {speakers.map(speaker => (
                <option key={speaker.id} value={speaker.name}>{speaker.name}</option>
              ))}
            </select>
          </label>
        </div>
        
        <div>
          <button className="submit-button" type="submit">CONVERT</button>
        </div>
        <div className="form-group">
            <button className="adv-button" type="button" onClick={toggleAdvanced}>
              ADVANCED SETTINGS
            </button>
          </div>
         
      </form>
      
      {errorMessage && <p className="error-message">{errorMessage}</p>}
        {isLoading && <Loading />}
        <div className="bottom-left"></div>
        <div className="bottom-right"></div>
    </div>
    <div id="audio-container">
      {audioUrl && showAudio && (
        <div className="audio-overlay">
          <div className="audio-wrapper">
            <audio
              controls
              src={"https://yatamix.com/api/download/" + audioUrl}
            >
              Your browser does not support the audio element.
              </audio>
              <button className="download-button" onClick={() => downloadFile("https://yatamix.com/api/download/" + audioUrl)}>
                Download
              </button>
            <button className="adv-button2" type="button" onClick={handleDone}>Done</button> {/* Add the Done button */}
          </div>
        </div>
      )}
      </div>
     {showAdvanced && (
          <div className="advanced-settings-overlay">
              <div className="form-group2">
              <label>
                Transpose:
                <input
                  type="range"
                  min="-12"
                  max="12"
                  value={transpose}
                  onChange={event => setTranspose(Number(event.target.value))}
                  className="slider"
                /><div>{transpose}</div>
              </label>
              </div>
              <div className="form-group2">
                <label>
                  Clustering ratio:
                  <input
                    type="number"
                    step="0.01"
                    value={clusteringRatio}
                    onChange={event =>
                      setClusteringRatio(event.target.value)
                    }
                  />
                </label>
              </div>
              <div className="form-group2">
                <label className="noise">
                  Noise scale:
                  <input
                    type="number"
                    step="0.01"
                    value={noiseScale}
                    onChange={event => setNoiseScale(event.target.value)}
                  />
                </label>
              </div>
              <button className="adv-button2" type="button" onClick={toggleAdvanced}>
              ⇐ Back
            </button>
            </div>
          )}
          <LoginPopup 
            show={showLoginPopup} 
            onDismiss={() => setShowLoginPopup(false)} 
          />
    </center>
    </div>
  );
};


export default SoVitsInference;