const DEBUG = false;

const request = async (method, endpoint, requestOptions) => {
  // Log the method and endpoint being called

  // Determine the hostname and set up request options
  const hostName = "yatamix.com";
  const protocol = "https:";
  const route = "/api";
  const url = `${protocol}//${hostName}${route}/${endpoint}`;

  const responseJson = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });


  //console.log(
  //  `Response from /${endpoint}:\n`,
  //  responseJson
  //);
  return responseJson;
};

const POST = async (endpoint, data) => {
    // Determine the hostname and set up request options
    const hostName = "yatamix.com";
    const protocol = "https:";
    const route = "/api";
    const url = `${protocol}//${hostName}${route}/${endpoint}`;
  
    const startTime = performance.now();
  
    let requestOptions = {
      method: "POST",
    };
  
    if (data instanceof FormData) {
      requestOptions.body = data;
    } else {
      requestOptions.headers = {
        "Content-Type": "application/json",
      };
      requestOptions.body = JSON.stringify(data);
    }
  
    const response = await fetch(url, requestOptions);

    //console.log(response);
  
    if (response.headers.get("content-type").startsWith("application/json")) {
      // Handle JSON response
      const responseJson = await response.json();
  
      const endTime = performance.now();
      const latency = endTime - startTime;
  
      //console.log(
      //  `Response from /${endpoint} (${Math.round(latency, 2)}ms):\n`,
      //  responseJson
      //);
  
      return responseJson;
    } else {
      // Handle non-JSON response
      throw new Error(`Response from /${endpoint} is not JSON`);
    }
  };
  
  export { POST };


export const PUT = (endpoint, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return request("PUT", endpoint, requestOptions);
};

export const DELETE = (endpoint) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return request("DELETE", endpoint, requestOptions);
};

export const GET = (endpoint) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return request("GET", endpoint, requestOptions);
};