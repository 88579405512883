import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import BIRDS from 'vanta/dist/vanta.birds.min';

const VantaBirds = () => {
  const vantaRef = useRef(0);

  useEffect(() => {
    if (!vantaRef.current) {
      vantaRef.current = BIRDS({
        el: '.login-container',
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        backgroundColor: 0x110d0d,
        color1: 0x1dfe01,
        color2: 0x1dfe01,
        wingSpan: 21.00,
        speedLimit: 7.00,
        separation: 24.00,
        alignment: 34.00,
        cohesion: 26.00,
        quantity: 3.00
      });
    }
    return () => {
      if (vantaRef.current) {
        vantaRef.current.destroy();
        vantaRef.current = null;
      }
    };
  }, []);

  return null;
};

export default VantaBirds;