import React, { useState, useEffect } from "react";
import LoginPage from "./components/LoginPage.js";
import Interface from "./components/Interface.js";
import NavBar from "./components/NavBar.js";
import { Analytics } from '@vercel/analytics/react';

import "./App.css";

import {
  BrowserRouter as Router,
} from "react-router-dom";

export let userEmail;

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  //const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn") === "true");
  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyData, setSurveyData] = useState(null);

  

  useEffect(() => {
    // stores the user state
    const loggedInUser = sessionStorage.getItem("userObject");
    const loggedInUserEmail = sessionStorage.getItem("userEmail");
    //const loggedInUser = sessionStorage.getItem("userObject");
    if (loggedInUser) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleSurveySubmit = (data) => {
    setSurveyData(data);
    setShowSurvey(false);
  };

  return (
    <>
    <Router>
          <div>
          
            <div>
              <NavBar />
              <LoginPage setIsLoggedIn={setIsLoggedIn} setUser={setUser} />
            </div>
          </div>
      
    </Router>
    <Analytics />
    </>
  );
};

export default App;
