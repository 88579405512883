import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app } from "./firebaseConfig"; 
import './loginPopup.css'

function LoginPopup({ show, onDismiss }) {
  if (!show) {
    return null;
  }

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
    try {
      const result = await signInWithPopup(auth, provider);
      // The signed-in user info.
      const user = result.user;
      console.log(user); // You can remove this line after confirming it works
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="login-popup">
        <div className="login-box">
            <p>YOU MUST BE SIGNED IN TO USE</p>
            <button className="goog-login" onClick={signInWithGoogle}>Sign in with Google</button>
            <button className="goog-close"onClick={onDismiss}>DONE</button>
        </div>
        
    </div>
  );
}

export default LoginPopup;
