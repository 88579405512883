import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBDBYOrKFWX8R3h0w_h_fl0IaCuX0WXxNg",
  authDomain: "pluggingai.firebaseapp.com",
  projectId: "pluggingai",
  storageBucket: "pluggingai.appspot.com",
  messagingSenderId: "946996913333",
  appId: "1:946996913333:web:38b34a6aa84688c2d34afc",
  measurementId: "G-VBCTX89M7T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
