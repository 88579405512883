import React, { useState, useEffect, useRef } from 'react';
import './LoginPage.css';
import logo from './../images/logo-it.PNG';
import Interface from './Interface';
import BIRDS from 'vanta/dist/vanta.birds.min';
import * as THREE from 'three';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app } from "./firebaseConfig"; 


function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showNewComponent, setShowNewComponent] = useState(false);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
    try {
      const result = await signInWithPopup(auth, provider);
      // The signed-in user info.
      const user = result.user;
      console.log(user); // You can remove this line after confirming it works
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
  };
  const handleContainerClick = () => {
    document.querySelector('.l-image-logo').classList.add('hidden');
    document.querySelector('.l-form-container').classList.add('grow');
    setTimeout(() => setShowNewComponent(true), 1000);
  };

  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect && !showNewComponent) {
      setVantaEffect(
        BIRDS({
          el: vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          backgroundColor: 0x110d0d,
          color1: 0x1be400,
          color2: 0x1be400,
          wingSpan: 21.00,
          speedLimit: 7.00,
          separation: 24.00,
          alignment: 34.00,
          cohesion: 26.00,
          quantity: 3.00
        })
      );
    } else if (vantaEffect && showNewComponent) {
      vantaEffect.destroy();
      setVantaEffect(0);
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect, showNewComponent]);

  return (
    <div>
    {showNewComponent ? (
      <Interface />
    ) : (
      <div className="login-container" ref={vantaRef} >
        <center style={{backgroundColor: '#110d0d00'}}>
          <div
            className="l-form-container"
            onClick={handleContainerClick}
          >
            <center style={{backgroundColor: '#110d0d00'}}>
              <img className="l-image-logo" alt="SpryList logo" src={logo} />
            </center>
            <div className="bottom-left"></div>
            <div className="bottom-right"></div>
          </div>
        </center>
      </div>
    )}
  </div>
  );
}

export default LoginPage;
